<template>
  <div class="c-imagesConsultation">
    <div class="c-imagesConsultation_main">
      <div
        v-if="mainImageType === 'static'"
        @mouseenter="hoverMainImage = true"
        @mouseleave="hoverMainImage = false"
        @click="openGallery"
      >
        <img
          :src="mainImageSrc"
          class="c-mainImage_static"
          :alt="mainImageAlt"
          loading="eager"
          fetchpriority="high"
          v-if="showMainImage"
        />
        <div
          class="c-mainImage_zoom"
          v-if="hoverMainImage"
        >
          <coconala-icon
            name="search"
            size="12px"
          />
          拡大
        </div>
      </div>

      <div v-else-if="mainImageType === 'movie'">
        <vuetube
          class="c-mainImage_movie"
          :is-active.sync="isVideoActive"
          :video-id="mainImageSrc"
          padding-type="calc"
          width="610"
          height="504"
          :thumbnail-alt="mainImageAlt"
          ref="vuetube"
          v-if="showMainImage"
        />
      </div>
    </div>

    <div class="c-imagesConsultation_thumbnail">
      <div class="c-thumbnailList">
        <div
          class="c-thumbnailList_wrap"
          :style="thumbPosition"
        >
          <div
            v-for="(item, index) in imageList"
            :key="`thumbnail${index}`"
            class="c-thumbnailList_item"
            :class="{ 'c-thumbnailList_item-active': thumbCurrent === index + 1 }"
            @mouseover="changeMainImage(index)"
          >
            <vuetube
              class="c-serviceImage_videoInner"
              :video-id="item.value"
              :is-active="false"
              padding-type="calc"
              width="66"
              height="54"
              :thumbnail-alt="`${serviceName} イメージ${index + 1}`"
              ref="vuetube"
              thumbnail-play-size="small"
              v-if="item.type === 'movie'"
            />
            <img
              :src="$coconala.serviceImageURLV2(item.value, 132, 108)"
              :alt="`${serviceName} イメージ${index + 1}`"
              loading="lazy"
              decoding="async"
              v-if="item.type === 'static'"
            />
          </div>
        </div>
      </div>

      <div
        class="c-imagesConsultation_thumbnailPager"
        v-if="thumbNum > thumbPerPage"
      >
        <span
          class="c-thumbnailPagerButton"
          @click="thumbnailPager()"
        >
          <coconala-icon :name="isThumbMaxPage ? 'chevron-left' : 'chevron-right'" />
        </span>
      </div>
    </div>
    <gallery
      ref="gallery"
      :items="galleryItemList"
    />
  </div>
</template>
<script>
const THUMB_PER_PAGE = 3
const THUMB_WRAP_WIDTH = 230

import Gallery from '~/components/molecules/Gallery'
import Vuetube from '~/components/molecules/Vuetube'

export default {
  name: 'ContentsImagesConsultation',
  components: {
    Gallery,
    Vuetube
  },
  props: {
    serviceName: {
      type: String,
      default: ''
    },
    serviceImageList: {
      type: Array,
      default: () => []
    },
    serviceMovieList: {
      type: Array,
      default: () => []
    },
    moviePriorityFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageList: [],
      galleryItemList: [],
      mainImage: '',
      mainImageType: '',
      mainImageAlt: `${this.serviceName} イメージ1`,
      showMainImage: true,
      hoverMainImage: false,
      thumbCurrent: 1,
      thumbCurrentPage: 1,
      thumbPerPage: THUMB_PER_PAGE,
      isVideoActive: false
    }
  },
  computed: {
    mainImageSrc() {
      if (this.mainImageType === 'movie') {
        return this.mainImage
      } else {
        return this.$coconala.serviceImageURLV2(this.mainImage, 460, 380, 'crop')
      }
    },
    thumbNum() {
      return this.serviceMovieList.length + this.serviceImageList.length
    },
    thumbMaxPage() {
      return Math.ceil(this.thumbNum / THUMB_PER_PAGE)
    },
    isThumbMaxPage() {
      return this.thumbCurrentPage === this.thumbMaxPage
    },
    thumbPosition() {
      if (this.thumbCurrentPage > 1) {
        return {
          left: `-${(this.thumbCurrentPage - 1) * THUMB_WRAP_WIDTH}px`
        }
      }
      return {
        left: 0
      }
    }
  },
  watch: {
    // mainImage変更時にvuetubeが更新されないため一度消して作り直す
    mainImage(before, after) {
      if (this.mainImageType === 'movie') {
        this.showMainImage = false
        this.$nextTick(() => {
          setTimeout(() => {
            this.showMainImage = true
          }, 50)
        })
      }
    }
  },
  created() {
    // イメージリストをセット
    if (this.moviePriorityFlag && this.serviceMovieList.length) {
      this.serviceMovieList.forEach(el => {
        this.imageList.push({ value: el.providerSiteMovieId, type: 'movie' })
      })
    }
    if (this.serviceImageList.length) {
      this.serviceImageList.forEach(el => {
        this.imageList.push({ value: el, type: 'static' })
        this.galleryItemList.push({
          src: this.$coconala.serviceImageURLV2(el, 1220, 1240, 'resize')
        })
      })
    }
    if (!this.moviePriorityFlag && this.serviceMovieList.length) {
      this.serviceMovieList.forEach(el => {
        this.imageList.push({ value: el.providerSiteMovieId, type: 'movie' })
      })
    }

    // 初期メイン画像設定
    if (this.moviePriorityFlag && this.serviceMovieList.length) {
      this.mainImage = this.serviceMovieList[0].providerSiteMovieId
      this.mainImageType = 'movie'
    } else if (this.serviceImageList.length) {
      this.mainImage = this.serviceImageList[0]
      this.mainImageType = 'static'
    } else if (this.serviceMovieList.length) {
      this.mainImage = this.serviceMovieList[0].providerSiteMovieId
      this.mainImageType = 'movie'
    }
  },
  methods: {
    thumbnailPager() {
      let page = this.thumbCurrentPage + 1
      if (page > this.thumbMaxPage) {
        page = 1
      }
      this.thumbCurrentPage = page
    },
    changeMainImage(index) {
      this.isVideoActive = false
      this.mainImageType = this.imageList[index].type
      this.mainImage = this.imageList[index].value
      this.mainImageAlt = `${this.serviceName} イメージ${index + 1}`
      this.thumbCurrent = index + 1
    },
    openGallery() {
      if (!this.$refs.gallery) return
      let current = this.thumbCurrent - 1
      if (this.moviePriorityFlag && this.serviceMovieList.length) {
        current = current - this.serviceMovieList.length
      }
      this.$refs.gallery.open(current, { bgOpacity: 0.7, tapToClose: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.c-imagesConsultation {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 258px;

  &_main {
    position: relative;
    margin-bottom: 12px;
    width: 230px;
    height: 190px;
  }

  &_thumbnail {
    position: relative;
    width: 230px;
    height: 56px;
  }

  &_thumbnailPager {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 18px;
    height: 56px;
    background: $color-white;
    opacity: 0.7;
  }

  ::v-deep .c-vuetube {
    &_thumbnailImage {
      opacity: 1;
      &:hover {
        opacity: 1;
      }
    }

    &_thumbnailPlay {
      &:hover {
        opacity: 1;
      }
    }
  }
}

.c-mainImage {
  position: relative;
  max-width: 230px;

  &_static {
    @include link-effect;

    cursor: pointer;
  }

  &_zoom {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 4px 8px;
    width: 66px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.65);
    color: $color-white;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }
}

.c-thumbnailList {
  overflow: hidden;
  width: 230px;
  height: 56px;

  &_wrap {
    position: relative;
    display: flex;
    transition-duration: 0.5s;
  }

  &_item {
    margin-right: 8px;
    width: 68px;
    height: 56px;
    border: 1px solid $ds2-color-gray-100;
    cursor: pointer;

    flex-shrink: 0;

    &-active {
      border-color: $ds2-color-brand-secondary-700;
    }

    &:nth-child(3n) {
      margin-right: 10px;
    }

    img {
      width: 66px;
      height: 54px;
    }
  }
}

.c-thumbnailPagerButton {
  display: flex;
  width: 18px;
  height: 56px;
  cursor: pointer;

  justify-content: center;

  i {
    text-align: center;

    align-self: center;
  }
}
</style>
