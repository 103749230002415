<template>
  <div
    class="c-vuetube"
    :style="boxStyle"
  >
    <div
      class="c-vuetube_paddingElement"
      :style="{ paddingTop: `${paddingRatio}%` }"
      v-if="paddingType === 'ratio'"
    ></div>
    <div
      class="c-vuetube_wrapper c-vuetube_wrapper-inline"
      :style="wrapperStyle"
      v-if="isActive && !isModal"
    >
      <DLoading
        :is-full-page="false"
        :active="!isReady"
      />
      <youtube
        class="c-vuetube_video"
        :video-id="vid"
        :player-vars="playerVars"
        :width="width"
        :height="height"
        :resize="resize"
        :resize-delay="resizeDelay"
        :fit-parent="true"
        @ready="onReady"
        ref="youtube"
      />
      <div
        class="c-mask js-mask"
        v-touch:tap="tapMovieControl"
        v-touch:swipe.left="swipeMovieControl"
        v-touch:swipe.right="swipeMovieControl"
        v-if="isTapEvent"
      ></div>
    </div>
    <div
      class="c-vuetube_wrapper c-vuetube_wrapper-modal"
      :style="wrapperStyle"
      v-if="isActive && isModal"
    >
      <OModal
        class="c-videoModal"
        :active="isActive"
        width="100%"
        @close="close"
      >
        <DLoading
          :is-full-page="false"
          :active="!isReady"
        />
        <youtube
          class="c-vuetube_video"
          :video-id="vid"
          :player-vars="playerVars"
          :width="width"
          :height="height"
          :resize="resize"
          :resize-delay="resizeDelay"
          :fit-parent="true"
          @ready="onReady"
          ref="youtube"
        />
        <div
          class="mask js-mask"
          v-touch:tap="tapMovieControl"
          v-touch:swipe.left="swipeMovieControl"
          v-touch:swipe.right="swipeMovieControl"
          v-if="isTapEvent"
        ></div>
      </OModal>
    </div>
    <div
      class="c-vuetube_thumbnail"
      :style="wrapperStyle"
      @click.prevent="$emit('update:isActive', true)"
      v-if="!isActive"
    >
      <template v-if="getType === 'swiper-lazy'">
        <img
          class="c-vuetube_thumbnailImage swiper-lazy"
          :src="thumbnailUrl"
          :width="width"
          :height="height"
          :alt="thumbnailAlt"
          loading="lazy"
          decoding="async"
          @load="$emit('thumbnail-load')"
          @error="$emit('thumbnail-error')"
        />
        <div class="swiper-lazy-preloader"></div>
      </template>
      <template v-else-if="getType === 'swiper-immediate'">
        <img
          class="c-vuetube_thumbnailImage"
          :src="thumbnailUrl"
          :width="width"
          :height="height"
          :alt="thumbnailAlt"
          @load="$emit('thumbnail-load')"
          @error="$emit('thumbnail-error')"
        />
      </template>
      <template v-else>
        <img
          class="c-vuetube_thumbnailImage"
          :src="thumbnailUrl"
          :width="width"
          :height="height"
          :alt="thumbnailAlt"
          loading="lazy"
          decoding="async"
          @load="$emit('thumbnail-load')"
          @error="$emit('thumbnail-error')"
        />
      </template>
      <CoconalaIcon
        name="play"
        class="c-vuetube_thumbnailPlay"
        :class="`c-vuetube_thumbnailPlay-${thumbnailPlaySize}`"
      />
    </div>
  </div>
</template>
<script>
import DLoading from '~/components/atoms/DS2/DLoading'

export default {
  name: 'Vuetube',
  components: { DLoading },
  props: {
    videoId: {
      type: String
    },
    url: {
      type: String
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    isModal: {
      type: Boolean,
      default: false
    },
    isTapEvent: {
      type: Boolean,
      default: false
    },
    playerVars: {
      type: Object,
      default() {
        return {
          autoplay: 1
        }
      }
    },
    width: {
      type: [Number, String],
      default: 640
    },
    height: {
      type: [Number, String],
      default: 360
    },
    resize: {
      type: Boolean,
      default: false
    },
    resizeDelay: {
      type: Number,
      default: 200
    },
    backgroundColor: {
      type: String,
      default: '#000000'
    },
    swiperLazy: {
      type: Boolean,
      default: false
    },
    paddingType: {
      type: String,
      default: 'ratio'
    },
    paddingRatio: {
      type: [Number, String],
      default: 100
    },
    skipThumbnail: {
      type: Boolean,
      default: false
    },
    thumbnailPlaySize: {
      type: String,
      default: 'normal'
    },
    thumbnailAlt: {
      type: String,
      default: ''
    },
    getType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isReady: false,
      contentWidth: '100%'
    }
  },
  methods: {
    updateWidth() {
      this.contentWidth = `${this.$el.parentNode.clientWidth}px`
    },
    play() {
      this.isPlay = true
      this.$refs.youtube.player.playVideo()
    },
    stop() {
      this.isPlay = false
      this.$refs.youtube.player.stopVideo()
    },
    onReady() {
      this.isReady = true
      this.$emit('ready')
    },
    close() {
      event.preventDefault()
      this.stop()
      this.$emit('update:isActive', false)
    },
    tapMovieControl() {
      if (this.isPlay) {
        this.stop()
      } else {
        this.play()
      }
    },
    swipeMovieControl() {
      this.stop()
    }
  },
  computed: {
    thumbnailUrl() {
      return `https://img.youtube.com/vi/${this.vid}/hqdefault.jpg`
    },
    vid() {
      return this.videoId || this.videoIdFromUrl || null
    },
    videoIdFromUrl() {
      return this.$youtube.getIdFromUrl(this.url)
    },
    player() {
      return this.$refs.youtube && this.$refs.youtube.player
    },
    boxStyle() {
      if (this.paddingType === 'ratio') {
        return {
          maxWidth: 'auto'
        }
      }
      if (this.paddingType === 'calc') {
        return {
          maxWidth: `${this.width}px`
        }
      }
      return {}
    },
    wrapperStyle() {
      if (this.paddingType === 'ratio') {
        return {
          backgroundColor: this.backgroundColor
        }
      }
      if (this.paddingType === 'calc') {
        return {
          paddingBottom: `calc(${this.height} / ${this.width} * 100%)`,
          maxWidth: `${this.width}px`,
          maxHeight: `${this.height}px`,
          backgroundColor: this.backgroundColor
        }
      }
    }
  },
  mounted() {
    if (this.skipThumbnail) {
      this.$emit('update:isActive', true)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-vuetube {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;

  &_wrapper {
    display: flex;
    width: 100%;

    &-inline {
      ::v-deep iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-modal {
      ::v-deep .modal-background {
        background: rgba(0, 0, 0, 0.9);
      }

      ::v-deep .loading-overlay {
        .loading-background {
          background: none;
        }
      }

      ::v-deep iframe {
        min-height: 300px;
        width: 100%;
      }
    }
  }

  &_thumbnail {
    display: flex;
    width: 100%;
    height: 0;

    /* stylelint-disable */
    @media all and (-ms-high-contrast: none) {
      .swiper-lazy-preloader {
        display: none;
      }
    }
    /* stylelint-enable */
  }

  &_thumbnailImage {
    @include link-effect;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    opacity: 0.9;
    cursor: pointer;
    transform: translate(-50%, -50%);
  }

  &_thumbnailPlay {
    @include link-effect;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    padding-top: 7px;
    padding-left: 2px;
    width: 34px;
    height: 34px;
    border: solid 2px $color-white;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.7);
    color: $color-white;
    text-align: center;
    font-size: 15px;
    opacity: 0.9;
    cursor: pointer;
    transform: translate(-50%, -50%);

    &-small {
      padding-top: 5px;
      width: 24px;
      height: 24px;
      font-size: 10px;
    }

    &-large {
      padding-top: 19px;
      width: 68px;
      height: 68px;
      font-size: 25px;
    }
  }

  &_video {
  }

  .c-mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 230px;
    height: 190px;
  }
}
</style>
